<template>
	<div class="hotel-display">
		<BannerOther :img_url="img_url" :chinese_name='chinese_name' :english_name="english_name"></BannerOther>
		<div class="col-main-box">
			<Sidder :tab_selectIndex = 'tab_selectIndex'></Sidder>
			<div class="col-r-box">
				<!-- 导航栏 -->
				<div class="crumbs">
					<div class="cru-logo">
						<img src="../../assets/images/home/daohang_logo.png" alt="">
					</div>
					<el-breadcrumb separator=">">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item >酒店展示</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="relust" :class="hide? '':'showdiv'">搜索结果：</div>
				<!-- 有数据时 -->
				<div class="all-list-box" v-if="HaveData">
					<div class="list-item" v-for="item in dataList" @click="changepage(item.id,item.name)">
						<img :src="item.image" alt="">
						<div class="item-name">{{item.name}}</div>
						<div class="item-address">{{item.address}}</div>
					</div>
				</div>
				<el-pagination
				  v-if="HaveData"
				  background
				  @current-change='handleCurrentChange'
				  layout="prev, pager, next"
				  :total="total"
				  :page-size="pageSize"
				  next-text="下一页">
				</el-pagination>
				<!-- 无数据时 -->
				<div v-else class="nodata">
					<img src="../../assets/images/sidder/zanwuneirong.png" alt="">
					暂无数据~
				</div>
				
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getjiudianList
	} from "@/api/joggle.js"
	import BannerOther from '@/components/banner-other/index.vue'
	import Sidder from '@/components/sidder/index.vue'
	 export default{
		 name:'Hotel-Display',
		 props:['keyword'],
		 components: {
		 	BannerOther,
			Sidder
		 },
		 data(){
			 return{
				tab_selectIndex: 10,
				img_url: '',
				chinese_name: '酒店展示',
				english_name: 'HOTEL DISPLAY',
				dataList:[],
				total:0, // 总条数
				pageNum:1, // 当前页
				pageSize:10, // 每页条数
				top_keyword:'',
				hide:true,
				HaveData:true,
				options:[], //左侧菜单
			 }
		 },
		 watch:{
			 keyword(val,oldval){
				 var that = this
				 that.pageNum = 1;
				 that.top_keyword = val;
				 if(val == ''){
					 that.getList();
				 }else{
					 that.getList('搜索');
				 }
			 }
		 },
		 activated() {
		 	var that = this
			// 获取头图(栏目图)
			var company_info = JSON.parse(localStorage.getItem('company_info'));
			if(company_info){
				that.img_url = company_info.hotel_image;
			}
			that.top_keyword = that.$route.query.top_keyword;
			if(!that.top_keyword){
				that.top_keyword = ''
			}
			that.getList();
			that.options = JSON.parse(localStorage.getItem('options'));
			that.options.forEach((item,index)=>{
				if(item.name == that.chinese_name){
					that.tab_selectIndex = index
				}
			})
		 },
		 methods:{
			// 获取酒店列表
			getList(name){
				var that = this
				var obj = {
					page_no:that.pageNum,
					keyword:that.top_keyword
				}
				getjiudianList(obj).then(res => {
					if(res.code == 1){
						if(name == '搜索'){
							that.hide = false;
						}else{
							that.hide = true;
						}
						that.total = res.data.count;
						that.pageSize = res.data.page_size;
						that.dataList = res.data.list;
						if(that.dataList.length == 0){
							that.HaveData = false;
						}else{
							that.HaveData = true;
						}
					}
					
				})
			},
			// 分页
			handleCurrentChange(val){
				var that = this
				that.pageNum = val;
				that.getList();
			},
			changepage(id,name){
				var that = this
				that.$router.push({
					path:'/hotel-details',
					query: {
						hotelid: id,
						pagename:name,
						top_keyword:that.top_keyword
					}
				})
			}
		 }
	 }
</script>

<style lang="scss">
	@import '../../assets/css/public.scss';
	@import '../../assets/css/hotel-display.scss';
	
</style>